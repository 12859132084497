import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";
import rbiiIcon from "../images/rbiIcon.png";
import { Link } from "gatsby";


function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`RBII`, `mobile`, `react`, `ios`, 'android', 'apps', 'software', 'development', 'web', 'cross-platform', 'flutter', `for restaurants`, `admin` ]}
      />

      <section className="text-center">

        <Link
          to="/prices"
        >
          <h2 className="bg-yellow-400 text-2xl font-bold inline-block p-3">
            Software Development
          </h2>
        </Link>


        <p className="leading-loose text-xl my-4">
          We build the app you'd want to use, for you. Web or mobile apps that feel like your own. 
        </p>

        <p className="leading-loose text-l my-2">
          <Link
            to="/restaurants"
            className="font-bold underline text-gray-900"
          >
            For restaurants
          </Link>
          , a better way to face your customers and take orders. A mobile ordering platform with more than just your name on it. And an admin app that acts like a kitchens' mobile command center. 
        </p>

        <p className="leading-loose text-l my-2">
          Whether a big or small restaurant, there is value from having an app. Let us help you increase takeout orders at {" "}
          <Link
            to="/prices"
            className="font-bold underline text-gray-900"
          >
            low cost.
          </Link>
        </p>

        <p className="leading-loose text-l my-2">
          To get started with your own app, please  {" "}
          <Link
            to="/contact"
            className="font-bold underline text-gray-900"
          >
            contact us.
          </Link>
        </p>

        <Link to="/about">
          <img
            src={rbiiIcon}
            className="block mx-auto w-1/3"
            alt="RBII logo"
          />
        </Link>
      </section>

    </Layout>
  );
}

export default IndexPage;
